import * as React from "react";
import { MosaicHeaderProps } from ".";
import { TitleBlock } from "./TitleBlock";

export const DesktopMosaicHeader: React.FC<MosaicHeaderProps> = ({
  foodTileSrc,
  headerBackgroundColor,
  ...titleBlockProps
}: MosaicHeaderProps) => {
  return (
    <>
      <div
        style={{
          backgroundColor: headerBackgroundColor,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflowX: "hidden"
        }}
      >
        {[0, 0, 0].map((_, i) => (
          <img
            key={i}
            style={{
              width: "100%",
              maxWidth: 244,
              margin: "0 15px"
            }}
            src={foodTileSrc}
            alt="variety of rices"
          />
        ))}
        <div style={{ width: 642, height: 181 }}>
          <TitleBlock {...titleBlockProps} big />
        </div>
        {[0, 0, 0].map((_, i) => (
          <img
            key={i}
            style={{
              width: "100%",
              maxWidth: 244,
              margin: "0 15px"
            }}
            src={foodTileSrc}
            alt="variety of rices"
          />
        ))}
      </div>
    </>
  );
};
