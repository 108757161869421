import * as React from "react";
import { theme } from "../../../ui/theme";

interface Props {
  titleBackgroundColor: string;
  subheadTextColor: string;
  titleSvg: React.ReactNode;
  big?: boolean;
}

export const TitleBlock: React.FC<Props> = ({
  titleBackgroundColor,
  subheadTextColor,
  titleSvg,
  big,
}) => {
  return (
    <div
      style={{
        backgroundColor: titleBackgroundColor,
        padding: 18,
        width: big ? 642 : "100%",
        maxWidth: 642,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ maxWidth: 535, width: "100%", marginTop: "auto" }}>
        {titleSvg}
      </div>
      <div
        style={{
          paddingTop: 25,
          color: subheadTextColor,
          textAlign: "center",
          fontFamily: theme.fonts.secondary,
          fontWeight: 300,
          fontSize: big ? "1.0625rem" : "0.875rem",
        }}
      >
        <div
          style={{
            letterSpacing: 3.6,
          }}
        >
          <span style={{ display: "inline-block" }}>PART OF THE</span>{" "}
          <span style={{ display: "inline-block" }}>
            SAFFRON COOKBOOK COLLECTION
          </span>
        </div>
      </div>
    </div>
  );
};
