import React from "react";
import { ReverseOutlineButton } from "../../ui/Button";
import { BigBlackHeading } from "../LandingPage/components";
import { BodyCopy, PanelContainer } from "./components";
import { changeUrl } from "../../utils/changeUrl";

interface Props {
  foodSrc: string;
  paragraphs: string[];
}

export const BigFoodPanel: React.FC<Props> = ({ foodSrc, paragraphs }) => {
  return (
    <PanelContainer style={{ padding: 0 }}>
      <img
        style={{
          width: "100%",
          maxWidth: 644,
        }}
        alt="basmati rice"
        src={foodSrc}
      />
      <div
        style={{
          padding: "0 40px",
          paddingBottom: 40,
          flexDirection: "column",
          alignItems: "center",
          display: "flex",
        }}
      >
        <BigBlackHeading style={{ textAlign: "center", marginTop: 20 }}>
          Say goodbye to boring rice
        </BigBlackHeading>
        <div style={{ marginTop: 12, maxWidth: 634 }}>
          {paragraphs.map(p => (
            <BodyCopy style={{ marginBottom: 24 }} key={p}>
              {p}
            </BodyCopy>
          ))}
        </div>
        <ReverseOutlineButton
          onClick={() => {
            changeUrl("/cookbooks/_X68Er-JHd/spice-rice");
          }}
          variant="offWhite"
        >
          View Cookbook
        </ReverseOutlineButton>
      </div>
    </PanelContainer>
  );
};
