import React from "react";

export const CookbookTitle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1172.14 129.06">
    <defs />
    <g id="Layer_2" data-name="Layer 2">
      <g id="title">
        <path
          fill="#c3c3c1"
          d="M19.89 123.66Q7 119.16 0 111.78l5.22-10.26q6.84 6.84 18.18 11.07a66.43 66.43 0 0 0 23.4 4.23q16.92 0 25.38-6.21t8.46-16.11q0-7.56-4.59-12.06a31 31 0 0 0-11.25-6.93 181.94 181.94 0 0 0-18.54-5.31 194.79 194.79 0 0 1-22.68-6.93A37.24 37.24 0 0 1 9.09 53.1q-6-6.84-6-18.54a31.43 31.43 0 0 1 5-17.37q5-7.83 15.48-12.51T49.5 0a76.31 76.31 0 0 1 21.15 3 60.78 60.78 0 0 1 17.91 8.19l-4.5 10.62A61.93 61.93 0 0 0 67.14 14a63.15 63.15 0 0 0-17.64-2.66q-16.56 0-24.93 6.39T16.2 34.2q0 7.56 4.59 12.15a30.07 30.07 0 0 0 11.52 7q6.93 2.43 18.63 5.31a187.43 187.43 0 0 1 22.41 6.75 36.78 36.78 0 0 1 14.49 10.1q5.94 6.75 5.94 18.27a31 31 0 0 1-5.13 17.37Q83.52 119 72.9 123.57t-26.1 4.59a80.94 80.94 0 0 1-26.91-4.5zM207.72 12.6q13.68 11.52 13.68 31.68t-13.68 31.59Q194 87.3 169.92 87.3h-33.84v39.78h-13.32v-126h47.16q24.08 0 37.8 11.52zm-9.54 54.81q9.9-8.19 9.9-23.13 0-15.3-9.9-23.49t-28.62-8.19h-33.48v63h33.48q18.72 0 28.62-8.19zM250.2 1.08h13.32v126H250.2zM325.52 119.88a60.63 60.63 0 0 1-23.58-22.95 64 64 0 0 1-8.55-32.85 64 64 0 0 1 8.55-32.85 60.47 60.47 0 0 1 23.67-23A69.28 69.28 0 0 1 359.45 0a70.11 70.11 0 0 1 25.92 4.68 53.73 53.73 0 0 1 20.16 13.68l-8.46 8.46q-14.76-14.94-37.26-14.94a54.76 54.76 0 0 0-27.18 6.84 50 50 0 0 0-19.17 18.72 51.89 51.89 0 0 0-6.93 26.64 51.89 51.89 0 0 0 6.93 26.64 50 50 0 0 0 19.17 18.72 54.76 54.76 0 0 0 27.18 6.84q22.68 0 37.26-15.12l8.46 8.46a54.49 54.49 0 0 1-20.25 13.77 69.63 69.63 0 0 1-26 4.77 68.77 68.77 0 0 1-33.76-8.28zM523.07 115.56v11.52H434v-126h86.4V12.6h-73.11v44.82h65.16v11.34h-65.16v46.8z"
        />
        <path
          d="M691.73 129.06l-17.46-18q-16 17.1-42.12 17.1a56 56 0 0 1-21.87-4 35.12 35.12 0 0 1-15-11.34 27.25 27.25 0 0 1-5.4-16.65 31.7 31.7 0 0 1 7.2-20.34q7.2-9 24.48-18.9-9-9.36-12.42-15.93a29.79 29.79 0 0 1-3.42-14q0-12.06 8.64-19.44T637.55.18q13.5 0 21.42 6.66t7.92 18.36a25.42 25.42 0 0 1-6.3 16.8q-6.3 7.47-21.78 16.47l34.56 35.64a80.42 80.42 0 0 0 8.46-24.3l10.44 3.24q-3.42 16.92-11.16 29l18 18.54zm-25-25.74l-38-39.06q-14.94 8.46-20.61 15.3a23.37 23.37 0 0 0-5.67 15.3 19.52 19.52 0 0 0 8.37 16.47q8.37 6.21 22 6.21 20.93 0 33.89-14.22zM622.88 14.4a15.74 15.74 0 0 0-5.31 12.42 21.09 21.09 0 0 0 2.88 10.62q2.88 5 11.34 13.68 13.68-7.92 18.9-13.5t5.22-12.24q0-7.2-4.86-11.43t-13.5-4.23q-9.36 0-14.67 4.68z"
          fill="#d5c6a6"
        />
        <path
          fill="#c3c3c1"
          d="M855.17 127.08l-28.8-40.5a91.27 91.27 0 0 1-10.08.54h-33.84v40h-13.32V1.08h47.16q24.12 0 37.8 11.52t13.68 31.68q0 14.76-7.47 24.93T839 83.88l30.78 43.2zM844.55 67.5q9.9-8.28 9.9-23.22 0-15.3-9.9-23.49t-28.62-8.19h-33.48v63.18h33.48q18.72 0 28.62-8.28zM899.27 1.08h13.32v126h-13.32zM974.59 119.88A60.63 60.63 0 0 1 951 96.93a64 64 0 0 1-8.55-32.85A64 64 0 0 1 951 31.23a60.47 60.47 0 0 1 23.67-23A69.28 69.28 0 0 1 1008.52 0a70.11 70.11 0 0 1 25.92 4.68 53.73 53.73 0 0 1 20.16 13.68l-8.46 8.46q-14.76-14.94-37.26-14.94a54.76 54.76 0 0 0-27.18 6.84 50 50 0 0 0-19.17 18.72 51.89 51.89 0 0 0-6.93 26.64 51.89 51.89 0 0 0 6.93 26.64 50 50 0 0 0 19.17 18.72 54.76 54.76 0 0 0 27.18 6.84q22.68 0 37.26-15.12l8.46 8.46a54.49 54.49 0 0 1-20.25 13.77 69.63 69.63 0 0 1-26 4.77 68.77 68.77 0 0 1-33.76-8.28zM1172.14 115.56v11.52H1083v-126h86.4V12.6h-73.08v44.82h65.16v11.34h-65.16v46.8z"
        />
      </g>
    </g>
  </svg>
);
