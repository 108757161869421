import styled from "styled-components";
import { baseButton } from "../../ui/Button";
import { theme } from "../../ui/theme";

export const BodyCopy = styled("div")`
  font-family: ${theme.fonts.secondary};
  font-weight: 300;
  font-size: 1rem;
  color: #0d0d0d;
`;

export const PanelContainer = styled("div")`
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CookbookLandingButton = styled("button")`
  ${baseButton};
  font-family: ${theme.fonts.secondary};
  padding: 10px 2em;
  width: 100%;
  max-width: 350px;
  border: none;
  color: #fff;
  font-size: 1rem;
  background-color: #977535;
  border: 1px solid #977535;
  &:hover {
    background-color: #fff;
    color: #977535;
  }
`;
