import * as React from "react";
import { MosaicHeaderProps } from ".";
import { TitleBlock } from "./TitleBlock";

export const MobileMosaicHeader: React.FC<MosaicHeaderProps> = ({
  foodTileSrc,
  headerBackgroundColor,
  ...titleBlockProps
}) => {
  return (
    <>
      <div
        style={{
          backgroundColor: headerBackgroundColor,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflowX: "hidden"
        }}
      >
        {[0, 0, 0].map((_, i) => (
          <img
            key={i}
            style={{
              width: "100%",
              maxWidth: 377,
              flexShrink: 0,
              margin: "0 15px"
            }}
            src={foodTileSrc}
            alt="variety of rice"
          />
        ))}
      </div>
      <div
        style={{
          padding: 20,
          paddingTop: 0,
          backgroundColor: headerBackgroundColor
        }}
      >
        <TitleBlock {...titleBlockProps} />
      </div>
    </>
  );
};
