import * as React from "react";
import styled from "styled-components";
import { DesktopMosaicHeader } from "./DesktopMosaicHeader";
import { MobileMosaicHeader } from "./MobileMosaicHeader";

export interface MosaicHeaderProps {
  headerBackgroundColor: string;
  titleBackgroundColor: string;
  subheadTextColor: string;
  titleSvg: React.ReactNode;
  foodTileSrc: string;
}

const DesktopWrapper = styled("div")`
  @media only screen and (max-width: 670px) {
    display: none;
  }
`;

const MobileWrapper = styled("div")`
  @media only screen and (min-width: 670px) {
    display: none;
  }
`;

export const MosaicHeader: React.FC<MosaicHeaderProps> = props => {
  return (
    <>
      <DesktopWrapper>
        <DesktopMosaicHeader {...props} />
      </DesktopWrapper>
      <MobileWrapper>
        <MobileMosaicHeader {...props} />
      </MobileWrapper>
    </>
  );
};
