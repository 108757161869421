import * as React from "react";
import { PrettyLink } from "../../ui/PrettyLink";
import { theme } from "../../ui/theme";
import { PanelContainer } from "./components";

const IMG_PADDING = 4;

export const AuthorBioPanel: React.FC<{ headshotSrc: string }> = ({
  headshotSrc,
}) => {
  return (
    <PanelContainer
      style={{
        backgroundColor: "#F5F4F0",
        borderTop: "10px solid #83704F",
      }}
    >
      <img
        style={{
          marginTop: 32,
          marginBottom: 24,
          height: 87 + 2 * IMG_PADDING,
          width: 67 + 2 * IMG_PADDING,
          border: "1px solid #D5C6A6",
          padding: IMG_PADDING,
        }}
        src={headshotSrc}
        alt="headshot"
      />
      <div
        style={{
          fontFamily: theme.fonts.secondary,
          fontWeight: 300,
          fontSize: "1.875rem",
        }}
      >
        About The Author
      </div>
      <div style={{ fontWeight: 300, marginTop: 8, maxWidth: 540 }}>
        Julie Awad is the cofounder of{" "}
        <PrettyLink backgroundColor="#F5F4F0" color="#977535" href="/">
          Saffron
        </PrettyLink>
        , a digital cookbook platform for creating and sharing recipes. The
        project was started to record and preserve her family recipes so she
        could pass them down to her sons.{" "}
        <p style={{ marginTop: 24 }}>
          Julie enjoys cooking recipes from all over the world for her family.
          She lives in Texas with her husband and two children. Follow her on
          Instagram{" "}
          <PrettyLink
            color="#977535"
            backgroundColor="#F5F4F0"
            href="https://www.instagram.com/jbawad/"
          >
            @jbawad
          </PrettyLink>
          .
        </p>
      </div>
    </PanelContainer>
  );
};
