import * as React from "react";
import styled from "styled-components";
import { Chunk } from "../../../ui/Chunk";
import { theme } from "../../../ui/theme";
import { BigBlackHeading } from "../../LandingPage/components";
import { BodyCopy, PanelContainer } from "../components";
import glPhone from "./gl-phone.jpg";
import recipePhone from "./recipe-phone.jpg";
import searchPhone from "./search-phone.jpg";
import { YouTubeVideo } from "../../../ui/YouTubeVideo";

const PhoneImg = styled("img")`
  max-width: 100%;
  width: 206px;
`;

const PhoneGroup = styled("div")`
  width: 206px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PhoneGrid = styled("div")`
  margin-top: 26px;
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  & > div {
    margin-bottom: 45px;
  }

  @media only screen and (min-width: 795px) {
    & > div {
      margin-right: 13px;
      margin-left: 13px;
      margin-bottom: 0px;
    }
    align-items: flex-start;
    flex-direction: row;
  }
`;

const phoneInfo = [
  {
    src: searchPhone,
    text: "Search by recipe title, ingredient, or author.",
  },
  {
    src: glPhone,
    text: "Add ingredients from recipes to a grocery list.",
  },
  {
    src: recipePhone,
    text:
      "Access recipes from your computer or phone with the iOS or Android App.",
  },
];

export const SaffronPanel = () => {
  return (
    <PanelContainer
      style={{
        backgroundColor: "#D5C6A6",
        paddingBottom: 55,
      }}
    >
      <div
        style={{
          paddingTop: 32,
          paddingBottom: 9,
          fontFamily: theme.fonts.secondary,
          fontSize: "1.0rem",
          width: "100%",
          textAlign: "center",
          letterSpacing: 2.6,
        }}
      >
        <Chunk>POWERED BY THE</Chunk> <Chunk>SAFFRON PLATFORM</Chunk>
      </div>
      <BigBlackHeading style={{ width: "100%", textAlign: "center" }}>
        Not just a cookbook
      </BigBlackHeading>
      <PhoneGrid>
        {phoneInfo.map(({ src, text }) => (
          <PhoneGroup key={text}>
            <PhoneImg src={src} />
            <BodyCopy style={{ textAlign: "center", marginTop: 10 }}>
              {text}
            </BodyCopy>
          </PhoneGroup>
        ))}
      </PhoneGrid>
      <YouTubeVideo id="id8QedWPeoA" />
    </PanelContainer>
  );
};
