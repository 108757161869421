import * as React from "react";
import { Footer } from "../../LandingPage/Footer";
import { AuthorBioPanel } from "../AuthorBioPanel";
import { BigFoodPanel } from "../BigFoodPanel";
import { MosaicHeader } from "../MosaicHeader/";
import { SaffronPanel } from "../SaffronPanel";
import basmatiRice from "./basmati-rice.jpg";
import { CookbookTitle } from "./CookbookTitle";
import foodTile from "./food-tile.jpg";
import headshot from "./headshot.jpg";
import { SEO } from "../../../components/seo";

const SpiceAndRice: React.FC = () => {
  return (
    <div style={{ width: "100%" }}>
      <SEO
        title="Spice and Rice Cookbook"
        description="An example Saffron digital cookbook called Spice and Rice."
      />
      <MosaicHeader
        headerBackgroundColor="#83704F"
        subheadTextColor="#000"
        titleBackgroundColor="#F5F4F0"
        titleSvg={<CookbookTitle />}
        foodTileSrc={foodTile}
      />
      <BigFoodPanel
        foodSrc={basmatiRice}
        paragraphs={[
          "Elevate your dinner by selecting rice that compliments the cuisine. Combine spices and fresh herbs to build flavor and bring out personality in your food that’s sure to please.",
          "Explore some new flavors in this collection of delicious recipes!",
        ]}
      />
      <SaffronPanel />
      <AuthorBioPanel headshotSrc={headshot} />
      <Footer />
    </div>
  );
};

export default SpiceAndRice;
